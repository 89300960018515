@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@social-container-cls: ~'@{ra-prefix}-social-container';
@social-container-row-cls: ~'@{social-container-cls}-row';

:export {
  socialContainerCls: @social-container-cls;
  socialContainerRowCls: @social-container-row-cls;
}

.@{social-container-cls} {
  .@{social-container-row-cls} {
    margin: 25px 0;
  }

  .ant-col {
    text-align: center;
  }
}
